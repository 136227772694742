import { useReducer, Reducer } from "react";

import i18n from "../../../i18n";
import { LocationData } from "../../../models/LocationData";

const t = (key: string) =>
  i18n.t(`facilityDetailsPage.modalEditProfile.editProfileForm.errors.${key}`);

export type EditProfileState = {
  data: {
    location: {
      profile: {
        description: string;
      };
    };
  };
  errors: Record<string, string>;
};

type EditProfileAction = {
  type: EditProfileActionTypes;
  payload: any;
};

enum EditProfileActionTypes {
  CHANGE_DESCRIPTION = "CHANGE_DESCRIPTION"
}

export const useEditProfileReducer = (locationData: LocationData) => {
  const [state, dispatch] = useReducer<
    Reducer<EditProfileState, EditProfileAction>
  >(reducer, generateInitialState(locationData));

  const changeDescription = (description: string) => {
    dispatch({
      type: EditProfileActionTypes.CHANGE_DESCRIPTION,
      payload: description
    });
  };

  return {
    state,
    update: {
      changeDescription
    }
  };
};

const reducer = (
  state: EditProfileState,
  action: EditProfileAction
): EditProfileState => {
  switch (action.type) {
    case EditProfileActionTypes.CHANGE_DESCRIPTION:
      const errors = state.errors;
      if (action.payload.length > 750) {
        errors["location.profile.description"] = t(
          "location.profile.description.too_long"
        );
      } else {
        delete errors["location.profile.description"];
      }
      return {
        ...state,
        data: {
          location: {
            profile: {
              description: action.payload
            }
          }
        },
        errors
      };
  }
};

const generateInitialState = (locationData: LocationData) => {
  return {
    data: {
      location: {
        profile: {
          description: locationData.profile ?? ""
        }
      }
    },
    errors: {}
  };
};
