import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  EditProfileState,
  useEditProfileReducer,
} from "./useEditProfileReducer";
import { LocationData } from "../../../models/LocationData";
import InputTextArea from "../../FormElement/InputTextarea";
import ModalConfirm from "../../ModalConfirm";

import "./styles.scss";

export interface IEditProfileFormProps {
  locationData: LocationData;
  onClose: () => void;
  post: (state: EditProfileState) => void;
}

export const EditProfileForm: React.FunctionComponent<IEditProfileFormProps> = (
  props
) => {
  const { onClose, post, locationData } = props;

  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityDetailsPage.modalEditProfile.editProfileForm.${key}`);

  const { state, update } = useEditProfileReducer(locationData);

  const [shownModalConfirm, setShownModalConfirm] = useState<boolean>(false);

  const handlePost = () => {
    setShownModalConfirm(false);
    post(state);
  };

  const handleChangeDescription = (description: string) => {
    update.changeDescription(description);
  };

  return (
    <div className="edit-profile-form">
      <div className="message-body">
        <h3>{t("description")}</h3>
        <InputTextArea
          name="description"
          value={state.data.location.profile.description}
          onChange={handleChangeDescription}
          errorLabel={state.errors["location.profile.description"]}
          classNameContainer={"message-body-input"}
          maxChars={750}
          showChars={true}
        />
      </div>
      <div className="create-local-post-bottom-btns bottom-btns">
        <div>
          {onClose && (
            <button className="btn btn-border" onClick={onClose}>
              {t("cancel")}
            </button>
          )}
        </div>
        <div>
          <button
            className={`btn btn-blue ${
              Object.keys(state.errors).length === 0 ? "" : "disabled"
            }`}
            onClick={() => {
              setShownModalConfirm(true);
            }}
          >
            {t("update")}
          </button>
        </div>
      </div>
      {shownModalConfirm && (
        <ModalConfirm
          title={"please_confirm_to_update_profile"}
          cancelLabel={"cancel"}
          confirmLabel={"update"}
          onClose={() => {
            setShownModalConfirm(false);
          }}
          onConfirm={handlePost}
        ></ModalConfirm>
      )}
    </div>
  );
};
