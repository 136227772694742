import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import useRefreshLocationData, {
  RefreshType,
} from "../../../containers/FacilityDetailsPage/hooks/useRefreshLocationData";
import { LocationData } from "../../../models/LocationData";
import DataSvc from "../../../services/dataSvc";
import { EditProfileForm } from "../EditProfileForm";
import { EditProfileState } from "../EditProfileForm/useEditProfileReducer";

interface IModalEditProfileProps {
  locationData: LocationData;
  onClose: () => void;
}

export const ModalEditProfile: React.FunctionComponent<
  IModalEditProfileProps
> = (props) => {
  const { locationData, onClose } = props;

  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityDetailsPage.modalEditProfile.${key}`);

  const refresh = useRefreshLocationData(locationData.id.toString());

  const handlePost = async (state: EditProfileState) => {
    try {
      await DataSvc.updateLocationProfile(Number(locationData.id), state.data);
      toast.success(t("success"));
      onClose();

      refresh(RefreshType.LOCATION);
    } catch (error) {
      // Do nothing. Axios should handle displaying the error automatically.
    }
  };

  return (
    <div className="modal modal-default modal-create-local-post">
      <div className="modal-mains">
        <button className="btn-close" onClick={onClose}></button>
        <div className="modal-mains__body">
          <div className="top-title">
            <h2>{t("edit_profile")}</h2>
          </div>
          <EditProfileForm
            onClose={onClose}
            post={handlePost}
            locationData={locationData}
          />
        </div>
      </div>
    </div>
  );
};
