import dayjs from "dayjs";
import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import {
  ChartData,
  OrgGroupAggregationMetricsData,
  OrgTimeSeriesMetricsData,
} from "../../../../models/ChartCollectionData";
import { getFromToDate } from "../../../../utils/chartPeriodUtils";
import { downloadCsv } from "../../../../utils/csvUtils";
import { compileToDataSource as compileToDatasourceBar } from "../BarChart/utils";
import { compileToDataSource as compileToDataSourcePie } from "../CircleChart/utils";
import {
  DataSource,
  compileToDataSource,
  createDataGroups,
} from "../LineChart/utils";
import { compileToDataSource as compileToDataSourceTable } from "../TableChart/utils";

export interface IDownloadCsvButtonProps {
  chartData: ChartData;
  chartDetailsData: OrgGroupAggregationMetricsData;
  chartDetailsDataForLines: OrgTimeSeriesMetricsData[];
  prevYearChartDetailsDataForLines: OrgTimeSeriesMetricsData[];
  forWindows: boolean;
}

const chooseLineDataSet = (
  chartDetailsData: OrgTimeSeriesMetricsData[],
  dataSourceConfig: DataSource[],
  isLastYear: boolean,
  chartData: ChartData
) => {
  const { chartDataDaysTemp, chartDataWeeksTemp, chartDataMonthsTemp } =
    createDataGroups(chartDetailsData, dataSourceConfig, isLastYear);
  if (chartData.time_unit === "day") {
    return chartDataDaysTemp;
  } else if (chartData.time_unit === "week") {
    return chartDataWeeksTemp;
  } else {
    return chartDataMonthsTemp;
  }
};

const downloadTableToCsv = (
  chartDetailsData: OrgGroupAggregationMetricsData
): string[] => {
  const [chartRowDataTemp, columnArrayTemp] =
    compileToDataSourceTable(chartDetailsData);

  const csvRows = [];

  csvRows.push(`,${columnArrayTemp.join(",")},Total`);
  chartRowDataTemp.forEach((rowItem) => {
    const rowValues = rowItem.values
      .map((value: string) => parseInt(value))
      .join(",");
    const rowTotal = rowItem.values.reduce(
      (acc: number, value: string) => acc + parseInt(value),
      0
    );
    csvRows.push(`${rowItem.name},${rowValues},${rowTotal}`);
  });

  const columnTotals = columnArrayTemp
    .map((_, colIndex: number) => {
      return chartRowDataTemp.reduce(
        (acc: number, rowItem) => acc + parseInt(rowItem.values[colIndex]),
        0
      );
    })
    .join(",");

  const grandTotal = chartRowDataTemp.reduce((acc: number, rowItem) => {
    return (
      acc +
      rowItem.values.reduce(
        (acc: number, value: string) => acc + parseInt(value),
        0
      )
    );
  }, 0);

  csvRows.push(`Total,${columnTotals},${grandTotal}`);

  return csvRows;
};

const downloadBarGraphToCsv = (
  chartDetailsData: OrgGroupAggregationMetricsData
): string[] => {
  const dataSourceTmp = compileToDatasourceBar(chartDetailsData);

  const unit = dataSourceTmp.dataSources[0].label;
  const csvRows = [["Name", `${unit}`]].concat(
    dataSourceTmp.dataForLine.map((d: any) => [d.name])
  );

  dataSourceTmp.dataForLine.forEach(
    (item: { value: any; name: string }, index: number) => {
      csvRows[index + 1].push(`${item.value}`);
    }
  );

  return csvRows.map((row) => row.join(","));
};

const downloadPieGraphToCsv = (
  charDetailData: OrgGroupAggregationMetricsData
): string[] => {
  const dataSourceTmp = compileToDataSourcePie(charDetailData);

  const csvRows = [["Type", `${dataSourceTmp[0]?.unit || ""}`, "%"]].concat(
    dataSourceTmp.map((d) => [d.name])
  );

  const total = dataSourceTmp.reduce((acc, item) => acc + item.data, 0);
  csvRows.push(["total", `${total}`, "100%"]);

  dataSourceTmp.forEach((item, index) => {
    const { data } = item;
    csvRows[index + 1].push(`${data}`);
    csvRows[index + 1].push(`${((data / total) * 100).toFixed(2)}%`);
  });

  return csvRows.map((row) => row.join(","));
};

const downloadLineGraphToCsv = (
  chartData: ChartData,
  chartDetailsDataForLines: OrgTimeSeriesMetricsData[],
  prevYearChartDetailsDataForLines: OrgTimeSeriesMetricsData[]
): string[] => {
  const skipLabelTranslation = !!(chartData.grouping1 || chartData.grouping2);
  const dataSourceConfig = compileToDataSource(
    chartDetailsDataForLines,
    skipLabelTranslation,
    false
  );

  const dataSet = chooseLineDataSet(
    chartDetailsDataForLines,
    dataSourceConfig,
    false,
    chartData
  );

  let csvRows = [["Date"]].concat(dataSet.dataSources.map((d) => [d.label]));

  dataSet.dataForLine.forEach((item) => {
    // After removing the name and dates, what is left (...rest) are the values for the line.
    // These come in { aa: 1, bb: 2, cc: 3 } format.
    // The logic below pushes the values in the correct place using the index to indicate which row to insert in.
    const { date, name, fullDate, ...rest } = item;
    csvRows[0].push(`${fullDate}`);

    Object.keys(rest).forEach((key, index) => {
      csvRows[index + 1].push(`${item[key]}`);
    });
  });

  if (chartData.compare_to_prev && !!prevYearChartDetailsDataForLines) {
    const prevYearDataConfig = compileToDataSource(
      prevYearChartDetailsDataForLines,
      skipLabelTranslation,
      true
    );

    const prevYearRows = prevYearDataConfig.map((d) => [d.label]);
    const prevYearDataSet = chooseLineDataSet(
      prevYearChartDetailsDataForLines,
      prevYearDataConfig,
      true,
      chartData
    );

    prevYearDataSet.dataForLine.forEach((item) => {
      const { date, name, ...rest } = item;
      Object.keys(rest).forEach((key, index) => {
        prevYearRows[index].push(`${item[key]}`);
      });
    });

    csvRows = [...csvRows, ...prevYearRows];
  }

  return csvRows.map((row) => row.join(","));
};

export const DownloadCsvButton: React.FunctionComponent<
  IDownloadCsvButtonProps
> = (props) => {
  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`analysisCollectionsPage.collectionChartArea.${key}`);

  const {
    chartData,
    chartDetailsDataForLines,
    prevYearChartDetailsDataForLines,
    chartDetailsData,
    forWindows,
  } = props;

  const downloadToCsv = () => {
    let csvRows: string[] = [];
    let chartTitle = chartData.name;

    let periodStart;
    let periodEnd;

    const { fromDateStr, toDateStr } = getFromToDate(chartData.period_option);

    if (chartData.period_option === "custom") {
      periodStart = chartData.period_start;
      periodEnd = chartData.period_end;
    } else {
      periodStart = fromDateStr
        ? fromDateStr.format("YYYY-MM-DD")
        : dayjs().endOf("day").subtract(20, "days").format("YYYY-MM-DD");

      periodEnd = toDateStr
        ? toDateStr.format("YYYY-MM-DD")
        : dayjs().endOf("day").format("YYYY-MM-DD");
    }

    if (chartData.type === "line") {
      csvRows = downloadLineGraphToCsv(
        chartData,
        chartDetailsDataForLines,
        prevYearChartDetailsDataForLines
      );
      chartTitle += ` ${periodStart} - ${periodEnd}`;
    } else if (chartData.type === "pie") {
      csvRows = downloadPieGraphToCsv(chartDetailsData);
      chartTitle += ` ${periodStart} - ${periodEnd}`;
    } else if (chartData.type === "bar") {
      csvRows = downloadBarGraphToCsv(chartDetailsData);
      chartTitle += ` ${periodStart} - ${periodEnd}`;
    } else if (chartData.type === "table") {
      csvRows = downloadTableToCsv(chartDetailsData);
    }

    csvRows = [
      ...csvRows,
      _t("internaltoolsPage.public.data_only_available_until", {
        maxDate: dayjs().subtract(4, "day").format("YYYY-MM-DD"),
      }),
    ];
    downloadCsv(csvRows, chartTitle, forWindows);
    ReactGA.event({
      category: "analysis-chart",
      action: "Download chart data",
    });
  };

  return (
    <button className="btn btn-border" onClick={downloadToCsv}>
      <i className="icons icon-saveload icon-saveload-blue"></i>
      {`${t("to_csv")}${forWindows ? " (Windows)" : ""}`}
    </button>
  );
};
