import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { useMe } from "../../../hooks/queries";
import { AccountType } from "../../../models/AccountData";

interface IEditProfileButtonProps {
  onOpenModal: () => void;
  innerText: string;
  gbpLink: string;
}

export const EditProfileButton: React.FunctionComponent<
  IEditProfileButtonProps
> = (props) => {
  const { onOpenModal, gbpLink } = props;

  const { t: _t } = useTranslation();
  const t = (key: string) =>
    _t(`facilityDetailsPage.basicChecklist.editProfileButton.${key}`);

  const { data: me } = useMe();

  // Organization admins cannot edit profile at this time
  const canEditProfile =
    me?.type === AccountType.ADMIN || me?.type === AccountType.LOC_ADMIN;

  const innerText = canEditProfile ? t("edit_profile") : t("view_profile");

  if (canEditProfile) {
    return (
      <button className="btn btn-border" onClick={onOpenModal}>
        {innerText}
      </button>
    );
  }

  return (
    <a
      href={gbpLink}
      className="btn btn-border"
      target="_blank"
      rel="noreferrer"
      onClick={() => {
        ReactGA.event({
          category: "GBP Link",
          action: "Click to GBP",
          label: "profile",
        });
      }}
    >
      {innerText}
    </a>
  );
};
