import React from "react";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { useMe } from "../../../hooks/queries";
import { AccountType } from "../../../models/AccountData";
import { LocationData } from "../../../models/LocationData";
import { percentage } from "../../../utils/common";
import TipsIcon from "../../TipsIcon";
import { CreateLocalPostButton } from "../CreateLocalPostButton";
import "./styles.scss";
import { EditProfileButton } from "../EditProfileButton";
import { UnRepliedReviewsButton } from "../UnRepliedReviewsButton";

export interface IBasicChecklistProps {
  locationData: LocationData;
  onOpenModalUnRepliedReviews: () => void;
  onClickCreatePostToGoogleBusinessProfile: () => void;
  onOpenModalEditProfile: () => void;
}

type GbpEditScreen =
  | "editprofile/info" // Edit profile
  | "editprofile/hoteldetails" // Edit hotel details
  | "promote/photos/menu" // Add media
  | "promote/updates/menu" // Add local post
  | "customers/reviews" // To reviews
  | ""; // To profile

export const BasicChecklist: React.FunctionComponent<IBasicChecklistProps> = (
  props
) => {
  const { t: _t } = useTranslation();
  const t = (key: string) => _t(`facilityDetailsPage.basicChecklist.${key}`);

  const { data: me } = useMe();
  const isOrgAdmin = me?.type === AccountType.ORG_ADMIN;

  const {
    locationData,
    onClickCreatePostToGoogleBusinessProfile,
    onOpenModalUnRepliedReviews,
    onOpenModalEditProfile,
  } = props;

  // get Days Color
  const getDaysColor = (daysNumber: number) => {
    if (daysNumber > 30) {
      return "red";
    } else if (daysNumber > 14) {
      return "yellow";
    } else if (daysNumber > -1) {
      return "green";
    } else {
      return "red";
    }
  };

  // get Percentage Color
  const getPercentageColor = (replyPercentage: number) => {
    if (replyPercentage >= 80) {
      return "green";
    } else if (replyPercentage >= 50) {
      return "yellow";
    } else {
      return "red";
    }
  };

  const createGbpLink = (gbpScreen: GbpEditScreen): string => {
    const gbpLocIdNum = locationData.gbp_location_id.replace("locations/", "");
    return `https://business.google.com/n/${gbpLocIdNum}/profile?#mpd=${gbpScreen}`;
  };

  return (
    <div className="white-border-panel basic-checklist-module ">
      <div className="top-bar flex-grid">
        <div className="title">{t("basic_checklist")}</div>
      </div>
      <div className="four-items">
        <div className="row">
          <div className="col col-md-6 col-12">
            <div className="groups">
              <div className="gray-top">
                <span className="txt">
                  {locationData.has_lodging ? t("profile_hotel") : t("profile")}
                </span>
                <TipsIcon
                  tipsLabel={
                    locationData.has_lodging
                      ? t("profile_comment_hotel")
                      : t("profile_comment")
                  }
                />
              </div>
              <div className="content">
                <div className="color-txt">
                  <i
                    className={`icons ${
                      locationData.stat.has_profile ? "icon-done" : "icon-error"
                    }`}
                  ></i>
                  <span className="txt">
                    {locationData.stat.has_profile
                      ? t("complete")
                      : t("incomplete")}
                  </span>
                </div>
              </div>
              <div className="btn-area">
                <EditProfileButton
                  gbpLink={createGbpLink(
                    locationData.has_lodging
                      ? "editprofile/hoteldetails"
                      : "editprofile/info"
                  )}
                  innerText={t("view_profile")}
                  onOpenModal={onOpenModalEditProfile}
                />
              </div>
              {locationData.has_lodging ? (
                <small className="btn-footnote">
                  {t("view_profile_hotel_footnote")}
                </small>
              ) : (
                <small className="btn-footnote"></small>
              )}
            </div>
          </div>
          <div className="col col-md-6 col-12">
            <div className="groups">
              <div className="gray-top">
                <span className="txt">{t("last_post")}</span>
                <TipsIcon tipsLabel={t("last_post_comment")} />
              </div>
              <div className="content">
                <div
                  className={`color-txt ${getDaysColor(
                    locationData.stat.days_since_last_post
                  )}`}
                >
                  <span className="txt big-txt">
                    {locationData.stat.days_since_last_post > -1
                      ? locationData.stat.days_since_last_post === 0
                        ? t("today")
                        : locationData.stat.days_since_last_post
                      : "-"}
                  </span>
                  <span className="txt">
                    {locationData.stat.days_since_last_post > 0
                      ? t("days_ago")
                      : ""}
                  </span>
                </div>
              </div>
              <div className="btn-area">
                <CreateLocalPostButton
                  onClickCreatePostToGoogleBusinessProfile={
                    onClickCreatePostToGoogleBusinessProfile
                  }
                  innerText={`${t("new_post")}${isOrgAdmin ? "*" : ""}`}
                  gbpLink={createGbpLink("promote/updates/menu")}
                />
              </div>
              <small className="btn-footnote"></small>
            </div>
          </div>
          <div className="col col-md-6 col-12">
            <div className="groups">
              <div className="gray-top">
                <span className="txt">{t("last_media_post")}</span>
                <TipsIcon tipsLabel={t("last_media_post_comment")} />
              </div>
              <div className="content">
                <div
                  className={`color-txt ${getDaysColor(
                    locationData.stat.days_since_last_photo
                  )}`}
                >
                  <span className="txt big-txt">
                    {locationData.stat.days_since_last_photo > -1
                      ? locationData.stat.days_since_last_photo === 0
                        ? t("today")
                        : locationData.stat.days_since_last_photo
                      : "-"}
                  </span>
                  <span className="txt">
                    {locationData.stat.days_since_last_photo > 0
                      ? t("days_ago")
                      : ""}
                  </span>
                </div>
              </div>
              <div className="btn-area">
                <a
                  href={`${createGbpLink("promote/photos/menu")}`}
                  className="btn btn-border"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    ReactGA.event({
                      category: "GBP Link",
                      action: "Click to GBP",
                      label: "media",
                    });
                  }}
                >
                  {t("new_post")}*
                </a>
              </div>
              <small className="btn-footnote"></small>
            </div>
          </div>
          <div className="col col-md-6 col-12">
            <div className="groups">
              <div className="gray-top">
                <span className="txt">
                  {t("percentage_of_reviews_in_last_60_days_replied")}
                </span>
                <TipsIcon
                  tipsLabel={t(
                    "percentage_of_reviews_in_last_60_days_replied_comment"
                  )}
                />
              </div>
              <div className="content">
                <div
                  className={`color-txt ${getPercentageColor(
                    locationData.stat.reply_percentage
                  )}`}
                >
                  <span className="txt big-txt">
                    {percentage(locationData.stat.reply_percentage)}
                  </span>
                  {locationData.stat.reply_percentage && (
                    <span className="txt parent-txt">%</span>
                  )}
                </div>
              </div>
              <div className="btn-area">
                <UnRepliedReviewsButton
                  onOpenModalUnRepliedReviews={onOpenModalUnRepliedReviews}
                  innerText={`${t("view_reviews")}${isOrgAdmin ? "*" : ""}`}
                  gbpLink={createGbpLink("customers/reviews")}
                />
              </div>
              {isOrgAdmin && (
                <small className="btn-footnote">
                  {t("view_reviews_footnote")}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>
      <small className="footnote">{t("may_require_gbp_login")}</small>
    </div>
  );
};

export default BasicChecklist;
